import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { FooterComponent } from './footer/footer.component';
import { Sidebar_Directives } from '../shared/directives/side-nav.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ServiceGenerico } from 'src/app/@ConsolaCentral/@common/services/service-generico';
// RECOMMENDED (doesn't work with system.js)
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

// or
@NgModule({
    imports: [
        SharedModule,
        RouterModule,
    PerfectScrollbarModule,
    TranslateModule,
    BsDropdownModule.forRoot()
    ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        SidePanelComponent,
        FooterComponent,
        TranslateModule,
        Sidebar_Directives,

    ],
    declarations: [
        HeaderComponent,
        SideNavComponent,
        SidePanelComponent,
        FooterComponent,
        Sidebar_Directives,

    ],
  providers: [ServiceGenerico]
})


export class TemplateModule { }
