import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {TemplateService} from '../../shared/services/template.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ServiceGenerico } from '../../../@ConsolaCentral/@common/services/service-generico';
import { modulos_model } from '../../../@ConsolaCentral/@common/modelos/modulos-model';
import { LocalStorageService } from 'src/app/@ConsolaCentral/@common/services/local-storage.service';
import { sidebarDropdown } from '../../shared/directives/side-nav.directive';
import { UserIdleService } from 'angular-user-idle';
import { model_configuracion } from 'src/app/@ConsolaCentral/@common/configuracion/model_configuracion';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],

})
export class SideNavComponent implements AfterViewInit {

  public instancia = 0;
  isCollapse: boolean;
  public ListModulos: Array<modulos_model> = [];
  public ListGroupModulos: [] = [];
  public modelo_configuracion: model_configuracion;
  idOrganizacion: string;
  instancias: any[];
  InicioInstanciaUnica: number;

  constructor(private tplSvc: TemplateService, private router: Router,
    private userIdle: UserIdleService,
    private _peticionesService: ServiceGenerico,
    private localStorageService: LocalStorageService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (window.innerWidth < 992) {
          this.tplSvc.toggleSideNavCollapse(false);
        }
      }
    });
    this.modelo_configuracion = new model_configuracion();
  }

  private currentUser = this.localStorageService.getJsonValue('consola-user');

  @ViewChild(sidebarDropdown) sideBar: sidebarDropdown;
  ngOnInit() {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    this.ListModulos = this.localStorageService.getJsonValue("ListaMenuAgrupado");
    this.setupidleSession()

  }

  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }
  ngAfterViewInit(): void {

    this.sideBar.initSideBar();
  }

  setupidleSession() {
    // this.idOrganizacion = +this._route.snapshot.paramMap.get("instancia_id");
    this.idOrganizacion = localStorage.getItem("id_instancia");
    this.userIdle.setConfigValues({ idle: 1800, timeout: 1 });
  }

  irPagina(){
    
  }

}
