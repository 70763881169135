import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Subject';
import { modulos_model } from '../../modelos/modulos-model';
import { LocalStorageService } from '../../services/local-storage.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-auth-input-key-component',
  templateUrl: './auth-input-key-component.component.html',
  styleUrls: ['./auth-input-key-component.component.scss']
})
export class AuthInputKeyComponentComponent implements OnInit {
  //#region variables

  subject: Subject<boolean>;
  public claveInput:string;
//#endregion
  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.claveInput = null;
  }
  sinClave(){
    this.activeModal.close(null);
  }

  verificarClave() {
    this.activeModal.close(this.claveInput);
  }

}
