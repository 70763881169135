
    export class sesion_user_model_request
    {
        sesion_usuario?:string;
        sesion_contrasenia?:string;

    }

    
    export class modelo_usuario_sesion_request
    {
        id_usuario?:number;
        sesion_usuario?:string;
        sesion_contrasenia?:string;

    }

