import {Component, OnInit} from '@angular/core';
import {TemplateService} from '../../shared/services/template.service';
import {LocalStorageService} from '../../../@ConsolaCentral/@common/services/local-storage.service';
import {Router} from '@angular/router';
import { ServiceGenerico } from '../../../@ConsolaCentral/@common/services/service-generico';
import { model_configuracion } from 'src/app/@ConsolaCentral/@common/configuracion/model_configuracion';
import { sesion_user_model_response } from 'src/app/@ConsolaCentral/@common/modelos/Response/sesion_user_model_response';
import { NotificacionesResponse } from 'src/app/@ConsolaCentral/@common/modelos/Response/notificaciones_model_response';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import { usuario_model_request } from 'src/app/@ConsolaCentral/@common/modelos/Request/usuario_model_request';
import { modelo_usuario_sesion_request } from 'src/app/@ConsolaCentral/@common/modelos/Request/sesion_user_model_request';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[ServiceGenerico]
})
export class HeaderComponent implements OnInit {

  private currentUser:sesion_user_model_response = this.localStorageService.getJsonValue('consola-user');
  public model_user:modelo_usuario_sesion_request;
  public modelo_configuracion: model_configuracion;
  public notificaciones:Array<NotificacionesResponse>=[];

  searchModel: string;
  isCollapse: boolean;
  isOpen: boolean;
  searchActived = false;

  user: string;

  public idioma:string = 'en';
  constructor(
    private router: Router,
    private tplSvc: TemplateService,
    private localStorageService: LocalStorageService,
    private _servicioGenerico: ServiceGenerico,
    private userIdle: UserIdleService
  ) {
    this.modelo_configuracion = new model_configuracion();
  }

  ngOnInit(): void {
  //Start watching for user inactivity.
     this.userIdle.startWatching();
    
     // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => count);
     
    //  // Start watch when time is up.
     this.userIdle.onTimeout().subscribe(() => this.idleCloseSession());

    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);

    this.currentUser = this.localStorageService.getJsonValue('consola-user');

    if (this.currentUser) {
      var nombre = this.currentUser.user_sesion_nombre ? this.currentUser.user_sesion_nombre : ' ';
      var apellido_p = this.currentUser.user_sesion_apaterno ? this.currentUser.user_sesion_apaterno : ' ';
      var apellido_m = this.currentUser.user_sesion_amaterno ? this.currentUser.user_sesion_amaterno : ' ';
      var nombre_completo = nombre + ' ' + apellido_p + ' ' + apellido_m;

      this.user = nombre_completo;
    }
  }

  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }

  toggleSidePanelOpen() {
    this.isOpen = !this.isOpen;
    this.tplSvc.toggleSidePanelOpen(this.isOpen);
  }

  toggleSearch() {
    this.searchActived = !this.searchActived;

  }

  signOut() {
this.model_user=new modelo_usuario_sesion_request();
this.model_user.id_usuario= + this.currentUser.user_id;
    this._servicioGenerico.HttpPost(this.model_user,this.modelo_configuracion.servicio_usuarios + "/UsuarioSesion/logout").subscribe(async (tempdate) => {

      if (tempdate) {
        this.localStorageService.clearToken();

        this.router.navigateByUrl('/ConsolaCentral/login');
      } else {
        this.localStorageService.clearToken();

        this.router.navigateByUrl('/ConsolaCentral/login');
      }
    }, async err => {
      this.localStorageService.clearToken();

      this.router.navigateByUrl('/ConsolaCentral/login');
    });

   
  }

    idleCloseSession() {
    this._servicioGenerico.Httpget(this.modelo_configuracion.servicio_usuarios + "/UsuarioSesion/CerrarSesion?id_usuario=" + this.currentUser.user_id).subscribe(async (tempdate) => {
      if (tempdate) {
        this.localStorageService.clearToken();
        location.reload();
        this.router.navigate(['/']);
      } else {
        this.localStorageService.clearToken();
        location.reload();
        this.router.navigate(['/']);
      }
    }, async err => {
      this.localStorageService.clearToken();
      location.reload();
      this.router.navigate(['/']);
    });
    // this.router.navigate(['/']);
  } 


 



 
}
