import {Routes} from '@angular/router';

// Layouts
import {CommonLayoutComponent} from './@espire/common/common-layout.component';
import {AuthenticationLayoutComponent} from './@espire/common/authentication-layout.component';
import { AuthGuard } from './@ConsolaCentral/@common/guards/auth.guard';
import { KeyGuard } from './@ConsolaCentral/@common/guards/key-guard.guard';

export const AppRoutes: Routes = [
  {
    path: 'ConsolaCentral',
    component: CommonLayoutComponent,
    children: [
      
    
      {
        path: 'bienvenido',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo-base/modulo-base.module').then(m => m.ModuloBaseModule)
      },
      {
        path: 'mapa',
        loadChildren: () => import('./@Reportes/mapa-page/mapa-page.module').then(m => m.MapaPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-seguimiento',
        loadChildren: () => import('./@Reportes/reporte-seguimiento/reporte-seguimiento.module').then(m => m.ReporteSeguimientoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-detallado',
        loadChildren: () => import('./@Reportes/reporte-detallado/reporte-detallado.module').then(m => m.ReporteDetalladoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-detallado-cliente',
        loadChildren: () => import('./@Reportes/reporte-detallado-cliente/reporte-detallado-cliente.module').then(m => m.ReporteDetalladoClienteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporte-gecutivo-cliente',
        loadChildren: () => import('./@Reportes/tablero-general-ejecutivo-cliente/tablero-general-ejecutivo-cliente.module').then(m => m.TableroGeneralEjecutivoClienteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tablero-general',
        loadChildren: () => import('./@Reportes/tablero-general/tablero-general.module').then(m => m.TableroGeneralModule),
         canActivate: [AuthGuard]
      },
      {
        path: 'tablero-general-ejecutivo',
        loadChildren: () => import('./@Reportes/tablero-general-ejecutivo/tablero-general-ejecutivo.module').then(m => m.TableroGeneralEjecutivoModule),
       canActivate: [AuthGuard]
      },
      {
        path: 'reporte-ejecutivo',
        loadChildren: () => import('./@Reportes/reporte-ejecutivo/reporte-ejecutivo.module').then(m => m.ReporteEjecutivoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'home',
        loadChildren: () => import('./@ConsolaCentral/@common/pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion-usuarios/admin-usuarios.module').then(m => m.AdminUsuarios),
        canActivate: [AuthGuard]
      },
      
      {
        path: 'rol-modulo',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/asignacion-rol-modulo/asignacion_rol_modulo.module').then(m => m.AsignacionRolModuloModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'roles-usuarios',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion-roles-usuarios/admin-roles-usuarios.module').then(m => m.AdminRolesUsuarios),
        canActivate: [AuthGuard]
      },
      {
        path: 'modulos',
        loadChildren: () => import('./@ConsolaCentral/@catalogos/administracion-modulos/admin-modulos.module').then(m => m.AdminModulos),
         canActivate: [AuthGuard]
      },
      {
        path: 'personas',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion-personas/admin-personas.module').then(m => m.AdminPersonas),
        canActivate: [AuthGuard]
      },
      {
        path: 'catalogos',
        loadChildren: () => import('./@ConsolaCentral/@catalogos/administracion_catalogos/admin-catalogo.module').then(m => m.AdminCatalogos),
        canActivate: [AuthGuard]
      },
      {
        path: 'preguntas-frecuentes',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_preguntas_frecuentes/preguntas_frecuentes.module').then(m => m.AdminPreguntasFrecuentes),
        canActivate: [AuthGuard]
      },
  
      {
        path: 'envio_notificaciones',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_notificacion_push/admin_notificacion_push.module').then(m => m.AdminNotificacionPush),
        canActivate: [AuthGuard]
      },
   
      {
        path: 'proyectos',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_proyectos/admin-proyecto.module').then(m => m.AdminProyectos),
        canActivate: [AuthGuard]
      },
      {
        path: 'zonas',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_zonas/admin-zonas.module').then(m => m.AdminZonas),
        canActivate: [AuthGuard]
      },
      {
        path: 'asgzonas',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_asignacion_zonas/admin-asignacion-zonas.module').then(m => m.AdminAsgZonas),
        canActivate: [AuthGuard]
      },
      {
        path: 'asgObjetivos',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/administracion_asignacion_objetivos/admin-asignacion-objetivos.module').then(m => m.AdminAsgObjetivos),
        canActivate: [AuthGuard]
      },
      {
        path: 'appEvaluaciones',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/aprobacion_evaluaciones/aprobacion_evaluacion.module').then(m => m.AdminAprobacionEvaluaciones),
        canActivate: [AuthGuard]
      },
      {
        path: 'MCierreProyectos',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo_cierre_proyectos/modulo_cierre_proyectos.module').then(m => m.ModuloCierreProyectos),
        canActivate: [AuthGuard]
      },
      {
        path: 'SCompetencias',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo_supervision_competencias/supervision_competencias.module').then(m => m.SupervisionCompetenciaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'AReporteEjecutivo',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo_aprobaciones_reporte_ejecutivo/modulo_aprreporte_ejecutivo.module').then(m => m.ModuloARreporteEjecutivo),
        canActivate: [AuthGuard]
      },
      {
        path: 'Mpage',
        loadChildren: () => import('./@Reportes/mapa-page/mapa-page.module').then(m => m.MapaPageModule)
      },
      {
        path: 'Comunicacion',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo_comunicacion/modulo_comunicacion.module').then(m => m.MensajeNotificacionModule),
        canActivate: [AuthGuard]
      }    
    ]
  },
  {
    path: 'ConsolaCentral',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./@ConsolaCentral/@common/pages/session/session.module').then(m => m.SessionModule)
      },
      {
        path: 'recupera-password',
        loadChildren: () => import('./@Aplicacion/modulo-recupera-contraseña/recupera-password.module').then(m => m.RecuperaPasswordModule)
      },{
        path: 'no-autorizado',
        loadChildren: () => import('./@ConsolaCentral/@common/pages/no-autorizado/no-autorizado.module').then(m => m.NoAutorizadoModule)
      },
      {
        path: 'verEvaluacion/:id',
        loadChildren: () => import('./@Reportes/ver_evaluacion/ver_evaluacion.module').then(m => m.VerEvaluacionesModule)
      }
    ]
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'ConsolaCentral/login'
  },
  {
    path: 'ConsolaCentral',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'modulo-usuarios',
        loadChildren: () => import('./@Aplicacion/modulo-usuarios/modulo-usuarios.module').then(m => m.ModuloUsuariosModule)      
      }
    ]
  },
  {
    path: 'ConsolaCentral',
    component: CommonLayoutComponent,
    children: [
    {
        path: 'bienvenido-usuario',
        loadChildren: () => import('./@ConsolaCentral/@operaciones/modulo-base/modulo-base.module').then(m => m.ModuloBaseModule)
      },
      {
        path: 'password-usuario',
        loadChildren: () => import('./@Aplicacion/modulo-cambio-contraseña/cambio-password.module').then(m => m.PasswordModule),
        canActivate: [AuthGuard]
      }
    ]
  },
];

