<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row centrado">
              <div class="col">
                <h1 class="mrg-btm-5" style="color:#ffffff;font-weight: bold;"> Acceso no autorizado </h1>
              </div>
              
            </div>
            <hr />
            <div class="row centrado">
                <div class="col-md-4">
                </div>
                <div class="col-md-4">
                    <div class="modal-icon">
                        <i class="fa fa-exclamation-triangle icono"></i>    
                    </div>
                </div>
                <div class="col-md-4">
                </div>
                
            </div>
            <div class="row centrado">
                <div class="col">
                  <h2 class="mrg-btm-5">No tienes permiso para entrar al módulo</h2>
                </div>
              </div>
            <br />
            <div class="row centrado">
                <div class="col">
                    <button class="btn btn-default" (click)="volverInicio()">Volver al login</button>
                </div>
              </div>

           
          </div>
        </div>
      </div>
    </div>
  </div>