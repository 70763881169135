<div class="modal-header modal-block-primary">    
    <h4 class="modal-title">Necesita una clave para entrar a este módulo</h4>
  </div>
  <div class="modal-body clearfix">
    <div class="modal-icon">
        <i class="fa fa-exclamation-triangle icono"></i>    
    </div>
    <div class="modal-text">
      <p>Si no cuenta con clave, solicítela al administrador</p>
      <input type="password" type="text" id="form29" class="form-control form-control-sm ml-0"
        placeholder="Clave" [(ngModel)]="claveInput" >
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="sinClave()">No tengo clave</button>
    <button class="btn btn-primary right" (click)="verificarClave()">Enviar</button>
  </div>

  
