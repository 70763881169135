<div class="side-nav" sideBar>
  <div class="side-nav-inner">
    <div class="side-nav-logo ">
      <a href="" [routerLink]="['bienvenido']" style="height: 64px; width: 70px;">
        <div class="logo logo-white">
          </div>
      </a>
      <div class="mobile-toggle side-nav-toggle" style="color:white">
        <a (click)="toggleSideNavCollapse()">
          <i class="ti-arrow-circle-left"></i>
        </a>
      </div>
    </div>
    <ul class="side-nav-menu scrollable" [perfectScrollbar] style="font-size:15px;">
      <li class="nav-item dropdown" *ngFor="let modulos of ListModulos | keyvalue">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder" style="color:white;">
            <span class="material-icons">{{modulos.value[0].modulo_icono}}</span>
          </span>
          <span class="title" style="color:white;">{{modulos.key}}</span>
          <span class="arrow" style="color:white;">
            <i class="ti-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngFor="let menu of modulos.value  | keyvalue" role="menuitem">
            <a style="color:white;" class="dropdown-item" href="#" routerLinkActive="seccion-activa" routerLink="{{menu.value.modulo_route}}">
            {{menu.value.modulo_nombre}}</a>
          </li>
        </ul>
      </li>
    </ul>

  </div>
</div>
