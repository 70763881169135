import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from './@espire/shared/shared.module';
import {TemplateModule} from './@espire/template/template.module';
import {TemplateService} from './@espire/shared/services/template.service';

// Layout Component
import {CommonLayoutComponent} from './@espire/common/common-layout.component';
import {AuthenticationLayoutComponent} from './@espire/common/authentication-layout.component';

// Routing Module
import {AppRoutes} from './app.routing';

// App Component
import {AppComponent} from './app.component';
import { WebStorageService } from './@ConsolaCentral/@common/services/web-storage.service';
import { LocalStorageService } from './@ConsolaCentral/@common/services/local-storage.service';
import { MainService } from './@ConsolaCentral/@common/services/main.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './@ConsolaCentral/@common/services/auth-interceptor.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http'
import { UserIdleModule } from 'angular-user-idle';
import { AuthInputKeyComponentComponent } from './@ConsolaCentral/@common/shared/auth-input-key-component/auth-input-key-component.component';
import { NoAutorizadoComponent } from './@ConsolaCentral/@common/pages/no-autorizado/no-autorizado.component';

export function newTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { useHash: true, onSameUrlNavigation: "reload"}),
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: newTranslateLoader,
        deps: [HttpClient]
      }
    }),
    TemplateModule, 
    UserIdleModule.forRoot({idle: 1800, timeout: 1})  // 600 10 minutos - 1800 30 min 
   
  ],
  exports: [],
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    AuthenticationLayoutComponent,
    AuthInputKeyComponentComponent,
    NoAutorizadoComponent,
  ],
  providers: [
    TemplateService,
    WebStorageService,
    LocalStorageService,
    MainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
