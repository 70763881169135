import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable()
export class ServiceGenerico {
  constructor(public http: HttpClient) {
  }
  
  Httpget(servicio: string, head?:string) {
    const headers = new HttpHeaders({'Content-Type':'application/json'
    ,'Access-Control-Allow-Headers':'*'
    ,'Access-Control-Allow-Methods':'*'
    ,'Access-Control-Allow-Origin':'*'
    ,'Authorization':'Bearer '+head 
   });
    return this.http.get<any>(servicio, { headers })
      .pipe(map(resultado => {
        return resultado;
      }));
  }

  HttpGet(modelo: any, servicio: string) {
    return this.http.get<any>(servicio, modelo)
      .pipe(map(resultado => {
        return resultado;
      }));
  }

  HttpPost(modelo: any, servicio: string) {
    const headers = new HttpHeaders({'Content-Type':'application/json'
                                    ,'Access-Control-Allow-Methods':'*'
                                    ,'Access-Control-Allow-Origin':'*'
                                   });

    return this.http.post<any>(servicio, modelo, { headers })
      .pipe(map(resultado => {
        return resultado;
      }));
  }

  HttpPostList(modelo: any[], servicio: string) {
    const headers = new HttpHeaders({'Content-Type':'application/json'
    ,'Access-Control-Allow-Headers':'*'
    ,'Access-Control-Allow-Methods':'*'
    ,'Access-Control-Allow-Origin':'*'
   });

    return this.http.post<any>(servicio, modelo, { headers })
      .pipe(map(resultado => {
        return resultado;
      }));
  }

  HttpPut(modelo: any, servicio: string) {
    const headers = new HttpHeaders({'Content-Type':'application/json'
    ,'Access-Control-Allow-Headers':'*'
    ,'Access-Control-Allow-Methods':'*'
    ,'Access-Control-Allow-Origin':'*'
   });

    return this.http.put<any>(servicio, modelo, { headers })
      .pipe(map(resultado => {
        return resultado;
      }));
  }

  HttpDelete(modelo: any, servicio: string) {
    let options = {
        headers: new HttpHeaders({'Content-Type':'application/json'
        ,'Access-Control-Allow-Headers':'*'
        ,'Access-Control-Allow-Methods':'*'
        ,'Access-Control-Allow-Origin':'*'
       }), body: modelo
    };
    return this.http.delete<any>(servicio, options)
      .pipe(map(resultado => {
        return resultado;
      }));
  }
  HttpPatch(modelo: any, servicio: string, head?:string) {
    const headers = new HttpHeaders({'Content-Type':'application/json-patch+json'
    ,'Access-Control-Allow-Headers':'*'
    ,'Access-Control-Allow-Methods':'*'
    ,'Access-Control-Allow-Origin':'*'
    ,'Access-Control-Allow-Credentials':'true'
    ,'Authorization':'Bearer '+ head
   });
  return this.http.put<any>(servicio, modelo, { headers })
    .pipe(map(resultado => {
      return resultado;
    }));
  }
}
