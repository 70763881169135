import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { model_configuracion } from './@ConsolaCentral/@common/configuracion/model_configuracion';
import { idioma_model_response } from './@ConsolaCentral/@common/modelos/Response/idioma_model_response';
import { ServiceGenerico } from './@ConsolaCentral/@common/services/service-generico';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    public modelo_configuracion: model_configuracion;
    constructor(private translate: TranslateService, private _peticionesService: ServiceGenerico, ) {
        this.modelo_configuracion = new model_configuracion();
         // translate.addLangs(['en', 'es']);
        //  translate.setDefaultLang('es');
        // translate.use('es');
        this.idiomaDefault(); 
    }

    public async idiomaDefault(){
        // verificamos si tiene guardado algún idioma 
        let idioma = localStorage.getItem("idioma");
        
        // si no tiene un idioma guardado
        if(idioma == null){    
          // revisamos el idioma del dispositivo
          let idiomaDispositivo = this.translate.getBrowserLang()            
          let idiomaDisponible: boolean = await this.verificarIdiomaDisponible(idiomaDispositivo)
          // si el idioma del dispositivo esta disponible en el serivicio de idiomas, se coloca como idioma predeterminado
          if(idiomaDisponible)
              this.translate.setDefaultLang(idiomaDispositivo);
            else
          // de lo contrario se coloca el idioma en
              this.translate.setDefaultLang("en");                     
        }else{            
          let idiomaDisponible: boolean = await this.verificarIdiomaDisponible(idioma)
          // si tiene el idioma guardado y esta disponible en el servicio de idiomas
          if (idiomaDisponible)
            this.translate.setDefaultLang(idioma);  
            else
            // de lo contrario se coloca en
              this.translate.setDefaultLang("en");            
        }
      }


      public async verificarIdiomaDisponible(idioma: string){
        let encontrado: boolean = false;
        let listaIdiomas: Array<idioma_model_response> = await this.obtenerListadoDeIdiomas();    
        for (let i = 0; i < listaIdiomas.length; i++) {
          if (idioma == listaIdiomas[i].ca_idiomas_app_nombre){
            encontrado = true; 
            //return;        
          }
        }
        return encontrado;
      }
    
      public async obtenerListadoDeIdiomas() {  
        let listaIdiomas :Array<idioma_model_response> =[];
   

              return listaIdiomas;
      }
      
}

