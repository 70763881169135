import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { model_configuracion } from '../../configuracion/model_configuracion';
import { modelo_usuario_sesion_request } from '../../modelos/Request/sesion_user_model_request';
import { sesion_user_model_response } from '../../modelos/Response/sesion_user_model_response';
import { LocalStorageService } from '../../services/local-storage.service';
import { ServiceGenerico } from '../../services/service-generico';

@Component({
  selector: 'app-no-autorizado',
  templateUrl: './no-autorizado.component.html',
  styleUrls: ['./no-autorizado.component.scss']
})
export class NoAutorizadoComponent implements OnInit {
  public modelo_configuracion: model_configuracion;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private _servicioGenerico: ServiceGenerico

  ) {

    this.modelo_configuracion = new model_configuracion();

   }
  private currentUser:sesion_user_model_response = this.localStorageService.getJsonValue('consola-user');
  public model_user:modelo_usuario_sesion_request;

  ngOnInit(): void {
  }

  volverInicio(){
    this.localStorageService.clearToken();
    this.router.navigateByUrl('/ConsolaCentral/login');

  }

}
