<footer class="content-footer">
    <div class="footer">
        <div class="copyright">
            <span><b class="text-dark">Rayo Negro</b></span>
            <span class="go-right">
              <a href="#" routerLink="terminos-condiciones" class="text-gray mrg-right-15">
              </a>
            </span>
        </div>
    </div>
</footer>
