<div class="header navbar">
  <div class="header-container">
    <ul class="nav-left">
      <li>
        <a class="side-nav-toggle" (click)="toggleSideNavCollapse()">
          <i class="ti-view-grid"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <li class="dropdown">
       
        <ul class="dropdown-menu no-pdd-top no-pdd-btm" style="border-radius: 5px; ">
          <li>
            <a  class="dropdown-style text-center" style="background: #ffffff 0% 0% no-repeat padding-box; border-top-right-radius: 5px; border-top-left-radius: 5px;">
              <span style="color: white;"><b>{{ 'i18n_GENERAL.label_Notifications' | translate }}</b></span>
            </a>
          </li>
          <li role="separator" class="divider"></li>

          <div *ngFor="let item of notificaciones" >

            <li>
              <a  (click)="irNotificaciones(item.tbl_notificaciones_id)">
                <div class="row" >
                  <div class ="col-md-3">

                    <div class=" text-center icon-Notificacion-icono"></div>
                  </div>
                  <div class="col-md-9">
                    <span style="color: #ffffff;">{{ idioma == "en"? item.tbl_notificaciones_nombre_en: item.tbl_notificaciones_nombre }}</span>
                    <div class=" text-muted" style="  width: 250px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;">
                      {{ idioma == "en"? item.tbl_notificaciones_descripcion_en: item.tbl_notificaciones_descripcion}}
                    </div>
                    <!-- <span> {{item.tbl_notificaciones_descripcion}} </span> -->
                  </div>
                </div>


              </a>
            </li>
            <li role="separator" class="divider"></li>
          </div>

        </ul>
      </li>

      <li class="user-profile dropdown">
        <a href="" class="dropdown-toggle" data-toggle="dropdown">
          <img class="profile-img img-fluid" src="assets/images/profile/avatar-generic.jpg">
          <div class="user-info">
            <span class="name pdd-right-5">{{user}}</span>
            <i class="ti-angle-down font-size-10"></i>
          </div>
        </a>
        <ul class="dropdown-menu no-pdd-top no-pdd-btm">
          <li role="separator" class="divider"></li>
          <li role="separator" class="divider"></li>
          <li>
            <a (click)="signOut()">
              <i class="ti-power-off pdd-right-10"></i>
              <span>{{ 'i18n_LOGIN.label_logout' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>

  </div>
</div>
