import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { sesion_user_model_response } from '../modelos/Response/sesion_user_model_response';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private currentUser: sesion_user_model_response = this.localStorageService.getJsonValue('consola-user');
  auth_token = this.currentUser ? this.currentUser.auth_token ? this.currentUser.auth_token : null : null;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.currentUser = this.localStorageService.getJsonValue('consola-user');
    this.auth_token = this.currentUser ? this.currentUser.auth_token ? this.currentUser.auth_token : null : null;
    let idioma = this.localStorageService.getJsonValue('idioma') == 'es'?'es':'en';
    let request = req;
    if (this.auth_token) {
      request = req.clone({
        headers: req.headers.set( 'Authorization', `Bearer ${ this.auth_token }`)
        .set('idioma',idioma)
      });
    }
    return next.handle(request).pipe(
      catchError((err:HttpErrorResponse)=>{
        if (err.status === 401) {
          this.router.navigate(['/ConsolaCentral/login']);
        }
        return throwError( err );
      })
    );
  }

}
