import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { modulos_model } from "../modelos/modulos-model";

@Injectable({
  providedIn: 'root'  
})
export class AuthorizationService {
  constructor(private localStorageService: LocalStorageService) { }

  isAuthorized(Componente: string): boolean {

    if (this.localStorageService.getJsonValue("consola-user")) {
      let user: modulos_model[] = this.localStorageService.getJsonValue("ListaMenu");
      const resultado = user.find(Model => Model.modulo_route === Componente);
          if (resultado != null) {
            return true;
          }
          else {
            return false;
          }
        
        }
        else {
          return false;
        }

    return true;
    }
  }

