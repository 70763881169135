const baseURL = 'https://servicio.rayonegro-consolacentral.com.mx'; // GATEWAY DEV
// const baseURL = 'http://rayonegro-001-site1.dtempurl.com'; // GATEWAY DEV
//const baseURL = 'https://localhost:5001'; // GATEWAY DEV

export class model_configuracion {
  url_directa: string;

  constructor() {
    this.servicio_catalogos = baseURL + '/Catalogos';
    this.servicio_notificaciones = baseURL + '/Notificaciones';
    this.servicio_operaciones = baseURL + '/operaciones';
    this.servicio_storage = baseURL + '/Storage';
    this.servicio_usuarios = baseURL + '/Usuarios';
    this.servicio_reportes = baseURL + '/Reportes';

  }

  servicio_catalogos: string;
  servicio_notificaciones: string;
  servicio_operaciones: string;
  servicio_storage: string;
  servicio_usuarios: string;
  servicio_reportes: string;
}
