import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../services/local-storage.service';
import { AuthorizationService } from './authorization-service.module';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    if (route.routeConfig.path === 'login') {

      if (this.localStorageService.getJsonValue('consola-user')) {

        const isAuthorized = this.authorizationService.isAuthorized(route.routeConfig.path);
        if (!isAuthorized) {
          this.router.navigate(['/ConsolaCentral/home']);
        } 

        return isAuthorized;
      }

      return true;

    } else {

      if (this.localStorageService.getJsonValue('consola-user')) {


        const isAuthorized = this.authorizationService.isAuthorized(route.routeConfig.path);
        if (!isAuthorized) {
          this.router.navigate(['/ConsolaCentral/home']);
        }

        return isAuthorized;

      }

      this.router.navigate(['/ConsolaCentral/login']);

      return false;
    }





  }

}
